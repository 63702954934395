<template>
  <div class="wrapper">
    <div class="container">
      <div class="content">
        <h2>{{$t('userCenter.title')}}</h2>
        <div class="split-line"></div>
        <div class="block">
          <div class="block-title" v-if="$i18n.locale==='zh'">
            <span>我</span>
            <span>的</span>
            <span>服</span>
            <span>务</span>
            <span>商</span>
          </div>
          <div class="block-title" v-else>
            <span>Provider</span>
          </div>
          <div class="block-form">
            <i-icon name="icon-yonghu1"></i-icon>
            <el-form label-width="7em">
              <el-form-item :label="`${$t('userCenter.provider')}：`">{{parent.Name}}</el-form-item>
              <el-form-item :label="`${$t('userCenter.contacter')}：`">{{parent.Contacter}}</el-form-item>
              <!-- <el-form-item :label="`${$t('userCenter.provider')}：`">GPS</el-form-item>
              <el-form-item :label="`${$t('userCenter.contacter')}：`">--</el-form-item> -->
              <el-form-item :label="`${$t('userCenter.phone')}：`">{{parent.MustTel}}</el-form-item>
              <el-form-item :label="`${$t('userCenter.address')}：`">{{parent.Addr}}</el-form-item>
            </el-form>
          </div>
        </div>
        <div class="split-line" v-if="loginInfo.isUser"></div>
        <div class="block" v-if="loginInfo.isUser">
          <div class="block-title" v-if="$i18n.locale==='zh'">
            <span>修</span>
            <span>改</span>
            <span>信</span>
            <span>息</span>
          </div>
          <div class="block-title" v-else>
            <span>Change</span>
            <span>Password</span>
          </div>
          <div class="block-form">
            <i-icon name="icon-xiugaimima"></i-icon>
            <!-- <div class="form-content">
              <p class="tip">{{$t('userCenter.changePasswordTip')}}</p>
              <el-button type="primary" plain class="border" @click="openChangePassword" v-ripple>{{$t('userCenter.change')}}</el-button>
            </div> -->
            <el-row class="form-content">
              <el-col :span="12">
                <!-- <p class="label">登录密码</p> -->
                <p class="tip">{{$t('userCenter.changePasswordTip')}}</p>
                <el-button type="primary" plain class="border" @click="openChangePassword" v-ripple>{{$t('userCenter.change')}}</el-button>
              </el-col>
              <el-col :span="12" v-if="$i18n.locale==='zh'">
                <!-- <p class="label">手机号</p> -->
                <p class="tip">登录手机号：{{parent.Mobile||'未绑定手机号'}}</p>
                <el-button type="primary" plain class="border" @click="openChangeMobile" v-ripple>{{$t('userCenter.change')}}</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <template v-if="loginInfo.UserID===2">
          <div class="split-line" v-if="loginInfo.isUser && loginInfo.UserType != 961"></div>
        <div class="block" v-if="loginInfo.isUser && loginInfo.UserType != 961">
          <div class="block-title" v-if="$i18n.locale==='zh'">
            <span>自</span>
            <span>定</span>
            <span>义</span>
            <span>设</span>
            <span>置</span>
          </div>
          <div class="block-title" v-else>
            <span>Customize</span>
          </div>
          <div class="block-form">
            <i-icon name="icon-biaoti"></i-icon>
            <el-row class="form-content">
              <el-col :span="12">
                <p class="label">{{loginInfo.PlatformTitle}}</p>
                <p class="tip">{{$t('userCenter.customlizeTip1')}}</p>
                <el-button type="primary" plain class="border" @click="openTitle" v-ripple>{{$t('userCenter.change')}}</el-button>
              </el-col>

              <el-col :span="12">
                <div class="logoRow">
                  <div>
                    <p class="label">logo</p>
                    <p class="tip">{{$t('userCenter.customlizeTip2')}}</p>
                    <el-button type="primary" plain class="border" @click="openLogo" v-ripple>{{$t('userCenter.upload')}}</el-button>
                  </div>
                  <img :src="loginInfo.PlatformLogo" />
                </div>
              </el-col>

              <el-button class="fixed-content" icon="el-icon-setting" @click="openChangeAllLogo" v-tooltip="$t('userCenter.changeAllLogo')"></el-button>
            </el-row>
          </div>
        </div>
        </template>
      </div>
    </div>
    <change-password ref="password"></change-password>
    <dialog-title ref="dialogTitle"></dialog-title>
    <dialog-logo ref="dialogLogo" :title="$t('userCenter.changeLogo')"></dialog-logo>
    <dialog-change-all-logo ref="dialogChangeAllLogo" :title="$t('userCenter.changeAllLogo')"></dialog-change-all-logo>
    <dialog-change-mobile ref="dialogChangeMobile" @update="val => parent.Mobile=val"></dialog-change-mobile>
  </div>
</template>

<script>
import ChangePassword from '@/views/common/change-password'
import DialogTitle from './dialog-title'
import DialogChangeMobile from './dialog-change-mobile'
import DialogLogo from './dialog-logo'
import DialogChangeAllLogo from './dialog-change-all-logo'
import {mapGetters,mapMutations} from 'vuex'
import {BASE_URL} from '@/api/config'
import * as API from '@/api/info'
import miment from 'miment'
import { setLastLoginInfo, getLastLoginInfo } from '@/common/cache'
export default {
  name: 'info',
  data() {
    return {
      parent: {},
      imgSrc: null
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  components: {
    ChangePassword,
    DialogTitle,
    DialogLogo,
    DialogChangeAllLogo,
    DialogChangeMobile
  },
  created() {
    this.fetchData()
    this.imgSrc = `${BASE_URL}/assets/Logo?holdid=${this.loginInfo.HoldID}&d=${miment().format('YYYYMMDDhhmmss')}`
  },
  methods: {
    ...mapMutations({
      'setLoginInfo': 'SET_LOGIN_INFO'
    }),
    fetchData() {
      API.GetUserInfo().then(ret => {
        this.parent = Object.assign({}, ret.data)
      })
    },
    openChangeMobile() {
      this.$refs.dialogChangeMobile.open(this.parent.Mobile)
    },
    openChangePassword() {
      this.$refs.password.open()
    },
    openTitle() {
      this.$refs.dialogTitle.open()
    },
    openLogo() {
      this.$refs.dialogLogo.import().then(({path}) => {
        API.ModifyLogo(path).then(ret => {
          if (!ret.data.errCode) {
            this.$message({
              type: 'success',
              message: this.$t('common.success')
            })
            this.setLogo()
            //this.imgSrc = url
          } else {
            this.$message({
              type: 'error',
              message: ret.data.errMsg
            })
          }
        })
      })
    },
    setLogo() {
      const logoUrl = `${BASE_URL}/assets/Icon?holdid=${this.loginInfo.HoldID}&d=${miment().format('YYYYMMDDhhmmss')}`
      const info = Object.assign({}, this.loginInfo, {
        PlatformLogo: logoUrl
      })
      this.setLoginInfo(info)

      document.getElementById('ico').href = logoUrl
      const lastLoginInfo = getLastLoginInfo()
      lastLoginInfo.PlatformLogo = logoUrl
      setLastLoginInfo(lastLoginInfo)
    },
    openChangeAllLogo() {
      this.$refs.dialogChangeAllLogo.open()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.wrapper {
  padding: 8px;
  .container {
    //background-color: $home-backcolor;
    min-height: 600px;
    .content {
      padding: 48px 48px 56px;
      max-width: 850px;
      margin: 0 auto;
      h2 {
        text-align: center;
        color: $home-colorprimary;
      }
      .split-line {
        height: 1px;
        background-color: rgba($home-colorprimary, .2);
        margin: 36px 0;
      }
      .block {
        display: flex;
        align-items: stretch;
        .block-title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 40px;
          color: $home-colorprimary;
          line-height: 22px;
          font-size: 18px;
          font-weight: 700;
          margin-right: 48px;
        }
        .block-form {
          font-size: 14px;
          flex-grow: 1;
          display: flex;
          .icon {
            font-size: 40px;
            flex-grow: 0;
            flex-shrink: 0;
          }
          .el-form {
            flex-grow: 1;
            .el-form-item {
              margin-bottom: 0;
              $lineHeight: 28px;
              $color: $home-colorprimary;
              line-height: $lineHeight;

              & ::v-deep .el-form-item__label {
                line-height: $lineHeight;
                color: $color;
              }
              & ::v-deep .el-form-item__content {
                line-height: $lineHeight;
                color: rgba($color, .7);
              }
            }
          }
          .form-content {
            flex-grow: 1;
            margin-left: calc(3em - 12px);
            width: 100%;
            position: relative;
            .label {
              font-weight: 700;
              margin-bottom: 12px;
              color: $home-colorprimary;
            }
            .tip {
              color: rgba($home-colorprimary, .7);
            }
            .el-button {
              margin-top: 16px;
              //margin-left: 4em;
              // background-color: $home-backcolor;
              // &:hover {
              //   background-color: $--color-primary;
              // }
            }
            .fixed-content{
              position: absolute;
              z-index: 1;
              bottom: 18px;
              right: 0;
              font-size: 18px;
              height: 40px;
              width: 40px;
              padding: 0;
              border: none;
            }
          }
        }
      }
    }
  }
}
.logoRow {
  display: flex;
  & > div {
    flex-grow: 1;
  }

  img {
    width: 40px;
    height: 40px;
    outline: 1px dashed #DCDFE6;
    flex-shrink: 0;
  }
}
</style>
